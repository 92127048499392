<template>
    <div class="fullHeight">
        <SubBar
        :title="subBarTitle"
        route_name="Home"
      />
      <iframe width="100%" height= "100%" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=e1AQNRT6Ckeb2LT69E53_7hvxRKqfRhOhe3HvjmEN2ZUQTRGVVc2WEJISlVGMktENFJRRE9aNlc1VS4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
    </div>
</template>
<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
export default {
    components: {
        SubBar
    },
    data() {
        return {
            subBarTitle: "Formulaire de contact"
        }
    }
}
</script>